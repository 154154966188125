import {default as React} from "react"
import { Link } from "gatsby"
import LeafIcon from "~/images/icons/leaf.inline.svg"

const ServiceCard = ({ service: { service: { title = '', slug = '/', wpParent: { node: parent }}, description = ''}}) => {
  return (
    <div
      className="flex flex-col h-full items-center text-center px-8 py-12 bg-gray-100">
      <h3>{ title }</h3>
      <LeafIcon
        className="my-6" />
      <p>{ description }</p>
      <div
        className="mt-auto">
        <Link
          className="btn btn-secondary"
          to={`/${parent.slug}/${slug}`}>
          Learn More 
        </Link>
      </div>
    </div>
  )
}

export default ServiceCard