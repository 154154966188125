import {default as React} from "react"
import { Link } from "gatsby"
import {getImage, GatsbyImage} from "gatsby-plugin-image"
import { CgArrowLongRight } from 'react-icons/cg'

const ServiceIntro = ({ intro: { subtitle = '', title = '', description = '', image, button: { text = '', url = ''} }}) => {
  return (
    <div
      className="flex flex-wrap -mx-8">
      <div
        className="flex flex-col w-full md:w-1/2 px-8">
        <span
          className="text-xs font-medium uppercase text-secondary mb-4">
          {subtitle}
        </span>
        <h2
          className="text-6xl text-primary mb-8">
          {title}
        </h2>
        <p
          className="leading-loose mb-12">{description}</p>
        <div>
          <Link
            className="btn btn-secondary"
            to={url}>
            <span className="mr-4">{text}</span>
            <CgArrowLongRight />
          </Link>
        </div>
      </div>
      <div
        className="w-full md:w-5/12 ml-0 md:ml-auto px-8">
        <GatsbyImage
          image={getImage(image?.localFile)}
          alt={title} />
      </div>
    </div>
  )
}

export default ServiceIntro