/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import DotsIcon from "~/images/dots.inline.svg"
import { CgArrowLongRight } from 'react-icons/cg'
import Intro from "~/components/service/intro"
import ServiceCard from "~/components/service/card"
import Callout from "~/components/callout"
import Swiper from "~/components/swiper"
import TeamCard from "~/components/team/card"
import TestimonialSection from "~/components/testimonial"

const ServicePage = ({ data: { page } }) => {
  //used to determine if financial page for commpliance
  const financial = ['wealth-management', 'investment-services', 'trust-services'].some(slug => slug === page.slug)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [mailto, showMailto] = useState(false)

  useEffect(() => {
    (async () => {
      const token = await executeRecaptcha("mailto")
      try{
        const data = await fetch(`/api/recaptcha`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        }).then(response => {
          if(!response.ok){
            throw new Error('Response from API call was not Ok')
          }
          return response.json()
        }).then(data => data)
  
        if(data.score > 0.5){
          //safe to show mailto
          showMailto(true)
        }
      }catch(e){
        console.log(e)
      }
    })()
  }, [executeRecaptcha]) 
  return (
    <Layout
      financial={financial}>
      <Seo title={page.title} />
      <section
        sx={{
          backgroundImage: `url('${page.featuredImage?.node?.localFile?.publicURL}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderBottomRightRadius: '50% 10%'
        }}
        className="relative pt-44 bg-primary overflow-hidden">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="flex flex-col w-full md:w-1/3 px-8">
              <h1
                className="text-5xl md:text-7xl text-white mb-8 md:mb-20">{page.title}</h1>
              <small className="text-secondary mb-10">When it Matters</small>
            </div>
            { !!page.wpChildren.nodes.length && (

              <div className="flex items-end w-full md:w-2/3 px-8">
                <div
                  className="relative w-full bg-secondary bg-opacity-80 pt-8 pb-6 px-12">
                  <DotsIcon
                    className="absolute top-0 left-0 -mt-8 -ml-8 w-32 opacity-40" />
                  <ul
                    className="relative flex flex-wrap text-white list-none p-0 m-0 z-10">
                    { page.wpChildren.nodes.map(child => (
                      <li
                        key={child.databaseId}
                        className="mr-8 mb-4">
                        <Link
                          to={`/${page.slug}/${child.slug}`}
                          className="flex font-heading items-center py-2 text-white border-b border-white font-semibold">
                          <span className="mr-4">{ child.title }</span>
                          <CgArrowLongRight />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="container mx-auto px-8">
          <Intro
            intro={page?.servicePage?.intro} />
        </div>
      </section>
      <section>
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap justify-center -mx-8">
            <div className="w-full md:w-11/12 px-8">
              <div
                className="text-center leading-loose"
                dangerouslySetInnerHTML={{
                  __html: page.content
                }} />
            </div>
          </div>
        </div>
      </section>
      { !!page.servicePage?.subServices?.length && (
        <section className="py-12 md:py-24">
          <div className="container mx-auto px-8">
            <div className="flex flex-wrap -mx-8">
              { page.servicePage.subServices.map(service => (
                <div
                  key={service.service.databaseId}
                  className="w-full md:w-1/3 px-8 mb-8">
                  <ServiceCard
                    service={service} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      {/** Callout Section */}
      { page.callout && (
        <Callout
          callout={page.callout} />
      )}
      { !!page.servicePage?.team?.length && (
        <section className="relative py-24">
          <div
            className="absolute w-full top-0 left-0 h-2/3 bg-sand" />
          <div className="relative container mx-auto px-8 z-10">
            <div className="flex flex-col justify-center text-center mb-24">
              <h6 className="text-white">Always Here to Help</h6> 
              <h2 className="text-primary text-5xl mt-2">Meet Our {page.title} Team</h2>
            </div>
            <div className="flex flex-wrap -mx-8">
              <div className="relative w-full px-8">
                <DotsIcon
                  className="absolute top-0 right-0 -mt-8 -m8-8 w-32" />
                <Swiper
                  slidesPerView={1}
                  spaceBetween={40}
                  breakpoints={{
                    640: {
                      slidesPerView: 2
                    },
                    768: {
                      slidesPerView: 3
                    }
                  }}
                  slides={page.servicePage.team.map(member => <TeamCard key={member.databaseId} member={member} mailto={mailto} />)} />
              </div>
            </div>
          </div>
        </section>
      )}
      {/** Testimonial Section */}
      {financial ? (
        <section className="pb-24">
          <div className="container mx-auto px-8">
            <div className="flex flex-wrap -mx-8">
              <div className="w-full px-8 text-sm">
                <p className="mb-4">Trust products and services offered through Kirkwood Bank & Trust are not insured by FDIC, are not a deposit or other obligation of, or guaranteed by, the depository institution, and are subject to investment risks including possible loss of the principal amount invested.</p> 
                <p className="mb-4">Check out the background of this investment professional on FINRA’s <a className="text-blue-500" href="http://brokercheck.finra.org/" target="_blank" rel="noreferrer">BrokerCheck</a>. Investment Advisory Services offered through Independent Advisor Representatives of Cambridge Investment Research Advisors, Inc., a Registered Investment Adviser. Securities offered through Registered Representatives of Cambridge Investment Research Advisors, Inc., a Registered Investment Advisor. Securities offered through Registered Representatives of Cambridge Investment Research Inc., a broker-dealer, member <a className="text-blue-500" href="https://www.finra.org/" target="_blank" rel="noreferrer">FINRA</a>/<a className="text-blue-500" href="http://www.sipc.org/" target="_blank" rel="noreferrer">SIPC</a>, to residents of: ND, MN, AZ, SD, OH, FL and WY.</p>
                <p className="mb-4">Cambridge and Kirkwood Financial Services are not affiliated. Non-deposit investment products are not FDIC insured, not bank guaranteed, and may lose value. This communication is strictly intended for individuals residing in the states of ND, MN, AZ, SD, OH, FL and WY.   No offers may be made or accepted from any resident outside the specific state(s) referenced.</p>
                <h5 className="uppercase mb-4">IMPORTANT CONSUMER INFORMATION</h5>
                <p className="mb-4">This site is for informational purposes only and is not intended to be a solicitation or offering of any security and:</p>
                <ul>
                  <li className="font-bold mb-3">Representatives of a Registered Broker-Dealer (“BD”) or Registered Investment Advisor (“IA”) may only conduct business in a state if the representatives and the BD or IA they represent (a) satisfy the qualification requirements of, and are approved to do business by, that state; or (b) are excluded or exempted from that state’s registration requirements.</li>
                  <li className="font-bold">Representatives of a BD or IA are deemed to conduct business in a state to the extent that they would provide individualized responses to investor inquiries that involve (a) effecting, or attempting to effect, transactions in securities; or (b) rendering personalized investment advice for compensation.</li>
                </ul>
                <p className="mb-4">We are registered to offer securities in the following states: ND, MN, AZ, NE, SD, OH, FL and WY.</p>
                <p className="mb-4">We are registered to offer advisory business in the following states: ND, MN, AZ, SD, OH, FL and WY.</p>
              </div>
            </div>
          </div>
        </section>  
      ) : (
        <TestimonialSection /> 
      )}
    </Layout>
  )
}

export const query = graphql`
  query ServiceQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId }){
      databaseId
      slug
      title
      content
      wpChildren {
        nodes {
          ...on WpPage {
            id
            databaseId
            title
            slug
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      servicePage {
        intro {
          title
          subtitle
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          button {
            text
            url
          }
        }
        subServices {
          service {
            ...on WpPage {
              databaseId
              title
              slug
              wpParent{
                node {
                  slug
                }
              }
            }
          }
          description
        }
        team {
          ...on WpTeamMember{
            databaseId
            title
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
                  } 
                }
              }
            }
            teamMember{
              position
              location
              phone
              email
              mls
            }
          }
        }
      }
      callout {
        title
        subtitle
        description
        image {
          file {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          position
        }
        button {
          text
          url
        }
      }
    }
  }
`

export default ServicePage